import React from 'react';
//React-Bootstrap
import Carousel from 'react-bootstrap/Carousel'
//React Router-Dom
import { Link } from "react-router-dom";
//Images
import Boss from "../../img/microlend-boss.jpg"
import Team from "../../img/teambuilding/team9.JPG"
import SanRafael from "../../img/san-rafael.jpg"
import SanMaria from "../../img/santa-maria-14.jpg"
import FranchiseExpoIndo from "../../img/franchise-expo.jpg"
import ExpoCebu from "../../img/cebu-expo.jpg"
import MeetPH from '../../img/meet-ph.jpg'
import AmazingRace from '../..//img/amazing-race.jpg'
import FranchiseAsia from '../../img/franchise-asia-ph.jpg'
import FranchiseAsia2022 from '../../img/franchiseasiasmx/smx-1-day-3.jpg'
import GMACavite from '../../img/newgmacavite/gma-cavite-1.jpg'
import Angeles from '../../img/newangeles/angeles-1.jpg'
import CebuSouth from '../../img/newcebusouth/cebusouth4.jpg'
import Midsayap from '../../img/newmidsayap/midsayap4.jpg'
//React-Icon
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
//Style CSS
import './NewsAndEvents.css'
//Component
import SocialMediaComponents from './SocialMediaComponents';
import { motion } from 'framer-motion';
import { fadeAnimation } from '../animation';

const FranchiseAsia2022Components = ({ manilaexpo }) => {
    return (
        <div className="container nae-flex">
            <div className="nae-content-head">
                <motion.div
                    variants={fadeAnimation}
                    initial='hidden'
                    animate='show'
                    exit='exit'
                >
                    <h2 className="expo-title">International Franchise Expo<span className="expo-span"> Manila 2022</span></h2>
                    <p className="expo-date">October 14 - 16 ,2022</p>
                    <div>
                        <Carousel fade>
                            {manilaexpo.map((manila) => {
                                const { id, image } = manila;
                                return <Carousel.Item key={id} interval={3000}>
                                    <img
                                        className="d-block carousel-img"
                                        src={image}
                                        alt={image}
                                    />
                                </Carousel.Item>
                            })}
                        </Carousel>
                    </div>
                    <SocialMediaComponents />
                </motion.div>
                <div className="left-right-btn">
                    <div className="next-post-btn">
                        <Link to="/newlyopened-gmacavite-branch"><HiOutlineArrowNarrowLeft /> Prev Post</Link>
                    </div>
                    <div className="next-post-btn">
                        <Link to="/team-building">Next Post <HiOutlineArrowNarrowRight /></Link>
                    </div>
                </div>
            </div>

            <div className="other-post-cont">
                <h3>Other Posts</h3>
                <div className="other-post-subcont">
                    <div className="other-post-box ">
                        <img src={Midsayap} alt="opening-midsayap-northcotabato" />
                        <div>
                            <Link to="newlyopened-midsayap-branch">Newly Opened Midsayap, North Cotabato Branch</Link>
                            <p>March 28, 2023</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={CebuSouth} alt="opening-cebucity-cebu" />
                        <div>
                            <Link to="newlyopened-cebusouth-branch">Blessing of Cebu City, Cebu South Branch</Link>
                            <p>December 8, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box ">
                        <img src={Angeles} alt="opening-angeles-branch" />
                        <div>
                            <Link to="newlyopened-angeles-branch">Newly Opened Angeles City, Pampanga Branch</Link>
                            <p>November 12, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={GMACavite} alt="opening-gma-cavite" />
                        <div>
                            <Link to="newlyopened-gmacavite-branch">Newly Opened GMA, Cavite Branch</Link>
                            <p>November 9, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box select-active">
                        <img src={FranchiseAsia2022} alt="franchise-expo-2022" />
                        <div>
                            <Link to="franchise-expo-manila">International Franchise Expo, Manila 2022</Link>
                            <p>October 14 - 16, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={Team} alt="team-building" />
                        <div>
                            <Link to="/team-building">Summer Outing and Team Building</Link>
                            <p>June 4 - 5, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box ">
                        <img src={SanRafael} alt="opening-branch" />
                        <div>
                            <Link to="/newlyopened-sanrafael-branch">Newly Opened San Rafael, Bulacan Branch</Link>
                            <p>March 4, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={SanMaria} alt="opening-branch" />
                        <div>
                            <Link to="/newlyopened-santamaria-branch">Newly Opened Santa Maria, Bulacan Branch</Link>
                            <p>December 4, 2021</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={Boss} alt="micro-lending" />
                        <div>
                            <Link to="/microlend">Micro-lending firm gains ground by franchising</Link>
                            <p>July 8, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={FranchiseExpoIndo} alt="franchise expo indo" />
                        <div>
                            <Link to="/expoindo">Franchise Expo, Indonesia 2019</Link>
                            <p>July 8, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={ExpoCebu} alt="franchise expo cebu" />
                        <div>
                            <Link to="/expocebu">Franchise Expo, Cebu City 2019</Link>
                            <p>June 22, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={MeetPH} alt="meet ph" />
                        <div>
                            <Link to="/meetph">Meet PH’s first Micro Credit Financing on Franchise</Link>
                            <p>May 21, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={AmazingRace} alt="amazing race" />
                        <div>
                            <Link to="/amazingrace">AMAZING RACE 2019</Link>
                            <p>May 11, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={FranchiseAsia} alt="franchise asia" />
                        <div>
                            <Link to="/franchiseasia">Franchise Asia Philippines 2019</Link>
                            <p>April 14, 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FranchiseAsia2022Components