import GMACav1 from '../img/newgmacavite/gma-cavite-1.jpg';
import GMACav2 from '../img/newgmacavite/gma-cavite-2.jpg';
import GMACav3 from '../img/newgmacavite/gma-cavite-3.jpg';
import GMACav4 from '../img/newgmacavite/gma-cavite-4.jpg';
import GMACav5 from '../img/newgmacavite/gma-cavite-5.jpg';
import GMACav6 from '../img/newgmacavite/gma-cavite-6.jpg';
import GMACav7 from '../img/newgmacavite/gma-cavite-7.jpg';
import GMACav8 from '../img/newgmacavite/gma-cavite-8.jpg';
import GMACav9 from '../img/newgmacavite/gma-cavite-9.jpg';
import GMACav10 from '../img/newgmacavite/gma-cavite-10.jpg';
import GMACav11 from '../img/newgmacavite/gma-cavite-11.jpg';
import GMACav12 from '../img/newgmacavite/gma-cavite-12.jpg';
import GMACav13 from '../img/newgmacavite/gma-cavite-13.jpg';
import GMACav14 from '../img/newgmacavite/gma-cavite-14.jpg';
import GMACav15 from '../img/newgmacavite/gma-cavite-15.jpg';
import GMACav16 from '../img/newgmacavite/gma-cavite-16.jpg';
import GMACav17 from '../img/newgmacavite/gma-cavite-17.jpg';
import GMACav18 from '../img/newgmacavite/gma-cavite-18.jpg';
import GMACav19 from '../img/newgmacavite/gma-cavite-19.jpg';
import GMACav20 from '../img/newgmacavite/gma-cavite-20.jpg';
import GMACav21 from '../img/newgmacavite/gma-cavite-21.jpg';
import GMACav22 from '../img/newgmacavite/gma-cavite-22.jpg';
import GMACav23 from '../img/newgmacavite/gma-cavite-23.jpg';
import GMACav24 from '../img/newgmacavite/gma-cavite-24.jpg';


// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: GMACav1
    },
    {
        id: 2,
        image: GMACav2
    },
    {
        id: 3,
        image: GMACav3
    },
    {
        id: 4,
        image: GMACav4
    },
    {
        id: 5,
        image: GMACav5
    },
    {
        id: 6,
        image: GMACav6
    },
    {
        id: 7,
        image: GMACav7
    },
    {
        id: 8,
        image: GMACav8
    },
    {
        id: 9,
        image: GMACav9
    },
    {
        id: 10,
        image: GMACav10
    },
    {
        id: 11,
        image: GMACav11
    },
    {
        id: 12,
        image: GMACav12
    },
    {
        id: 13,
        image: GMACav13
    },
    {
        id: 14,
        image: GMACav14
    },
    {
        id: 15,
        image: GMACav15
    },
    {
        id: 16,
        image: GMACav16
    },
    {
        id: 17,
        image: GMACav17
    },
    {
        id: 18,
        image: GMACav18
    },
    {
        id: 19,
        image: GMACav19
    },
    {
        id: 20,
        image: GMACav20
    },
    {
        id: 21,
        image: GMACav21
    },
    {
        id: 22,
        image: GMACav22
    },
    {
        id: 23,
        image: GMACav23
    },
    {
        id: 24,
        image: GMACav24
    },
];