import Midsayap1 from '../img/newmidsayap/midsayap1.jpg';
import Midsayap2 from '../img/newmidsayap/midsayap2.jpg';
import Midsayap3 from '../img/newmidsayap/midsayap3.jpg';
import Midsayap4 from '../img/newmidsayap/midsayap4.jpg';
import Midsayap5 from '../img/newmidsayap/midsayap5.jpg';
import Midsayap6 from '../img/newmidsayap/midsayap6.jpg';
import Midsayap7 from '../img/newmidsayap/midsayap7.jpg';
import Midsayap8 from '../img/newmidsayap/midsayap8.jpg';
import Midsayap9 from '../img/newmidsayap/midsayap9.jpg';
import Midsayap10 from '../img/newmidsayap/midsayap10.jpg';




// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: Midsayap1
    },
    {
        id: 2,
        image: Midsayap2
    },
    {
        id: 3,
        image: Midsayap3
    },
    {
        id: 4,
        image: Midsayap4
    },
    {
        id: 5,
        image: Midsayap5
    },
    {
        id: 6,
        image: Midsayap6
    },
    {
        id: 7,
        image: Midsayap7
    },
    {
        id: 8,
        image: Midsayap8
    },
    {
        id: 9,
        image: Midsayap9
    },
    {
        id: 10,
        image: Midsayap10
    },




];