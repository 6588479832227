import React from "react";
//Style CSS
import './FeeSection.css'

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const FeeSection = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="fee-cont container">
            <h2 className="fee-main-title">The First Micro-Lending <span>in the Country</span></h2>
            <div className="fee-box first">
                <div className="fee-stage">
                    <i className="fas fa-money-check-alt"></i>
                    <h3>FRANCHISE FEE</h3>
                </div>
                <h2>P448,000</h2>
            </div>
            <div className="fee-box second">
                <div className="fee-stage">
                    <i className="fas fa-money-bill-wave"></i>
                    <h3>TOTAL INVESTMENT</h3>
                </div>
                <h2>STARTS at P3.5 Million</h2>
            </div>
            <div className="fee-box third">
                <div className="fee-stage">
                    <i className="fas fa-building"></i>
                    <h3>REQUIRED SPACE</h3>
                </div>
                <h2>at least 30 sqm</h2>
            </div>
        </motion.div>
    )
}

export default FeeSection;