import CebuSouth1 from '../img/newcebusouth/cebusouth1.jpg';
import CebuSouth2 from '../img/newcebusouth/cebusouth2.jpg';
import CebuSouth3 from '../img/newcebusouth/cebusouth3.jpg';
import CebuSouth4 from '../img/newcebusouth/cebusouth4.jpg';
import CebuSouth5 from '../img/newcebusouth/cebusouth5.jpg';
import CebuSouth6 from '../img/newcebusouth/cebusouth6.jpg';
import CebuSouth7 from '../img/newcebusouth/cebusouth7.jpg';
import CebuSouth8 from '../img/newcebusouth/cebusouth8.jpg';




// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: CebuSouth1
    },
    {
        id: 2,
        image: CebuSouth2
    },
    {
        id: 3,
        image: CebuSouth3
    },
    {
        id: 4,
        image: CebuSouth4
    },
    {
        id: 5,
        image: CebuSouth5
    },
    {
        id: 6,
        image: CebuSouth6
    },
    {
        id: 7,
        image: CebuSouth7
    },
    {
        id: 8,
        image: CebuSouth8
    }

];