import React from 'react'
//Style CSS
import './ContactSection.css';

const ContactSection = () => {
    return (
        <div className="contact-cont">
            <div className="container contact-flex">
                <div className="contact-box">
                    <p>Agnes Deniega</p>
                    <p><span>General Manager</span></p>
                    <p>+63 917 167 3652</p>
                    <p>franchise@ltgcreditline.com</p>
                </div>
                <div className="contact-box">
                    <p>Catherine Car</p>
                    <p><span>Human Resource Head</span></p>
                    <p>+63 965 098 5100</p>
                    <p>ltgcreditline.hr@gmail.com</p>
                </div>
                <div className="contact-box">
                    <p>Alexis Daimler Esquejo </p>
                    <p><span>Franchise Marketing Officer</span></p>
                    <p>+63 967 350 7944</p>
                    <p>marketing@ltgcreditline.com</p>
                </div>
            </div>
    </div>
    )
}

export default ContactSection;