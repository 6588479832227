import React from 'react';
//Style CSS
import './Franchise3rd.css'

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const Franchise3rd = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="container fr3rd-cont">
            <div className="accordion accordion-flush" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="accordion-button fr3rd-title" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            FRANCHISE FEE
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                            <h4 className="fr3rd-subtitle">PHP 448,000 (Inclusive of 12% VAT)</h4>
                            <ul>
                                <li>Use of LT&G Credit Line Trademark and Logos</li>
                                <li>Comprehensive Operations Manual</li>
                                <li>Hiring Assistance (excludes Jobstreet free, Facebooking boosting, and BI expenses etc.)</li>
                                <li>Research and Development Activities</li>
                                <li>Pre-Opening Support</li>
                                <li>Marketing Support</li>
                                <li>Business Guidance and Support</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button className="accordion-button collapsed fr3rd-title" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                            TURNKEY PACKAGE
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div className="accordion-body">
                            <h4 className="fr3rd-subtitle">PHP 649,600 (Inclusive of 12% VAT)</h4>
                            <ul>
                                <li>Leasehold Improvement (Excludes renovations such as floor tiles, glass doors, windows, ceilings, electrical works, and the likes.)</li>
                                <li>Management Information System</li>
                                <li>Initial Office Supplies</li>
                                <li>Signage</li>
                                <li>Staff Uniform</li>
                                <li>Grand Opening Budget</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                        <button className="accordion-button collapsed fr3rd-title" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                            TRAINING EXPENSES
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                        <div className="accordion-body">
                            <h4 className="fr3rd-subtitle">PHP 220,000 (Inclusive of 12% VAT)</h4>
                            <h5 className="fr3rd-sub">Initial Batch Of Nine (9) Trainees</h5>
                            <ul>
                                <li>Training Program</li>
                                <li>Trainees Allowances</li>
                                <li>Lodging</li>
                                <li>Meals: Snacks, Lunch (During Classroom Training), and Dinner </li>
                                <p>Note: Transportation and Meal Expense To and From the Training Center and back to their place of origin is not included and to be shouldered by the Franchisee.</p>
                            </ul>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <button className="accordion-button collapsed fr3rd-title" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                PRE-OPERATING EXPENSES AND WORKING CAPITAL
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                            <div className="accordion-body">
                                <h4 className="fr3rd-subtitle">PHP 2,182,400 </h4>
                                <ul>
                                    <li>Working Capital</li>
                                    <li>Lease, Security Deposit & Advance</li>
                                    <li>Business Registration (Permits & Licenses)</li>
                                    <li>Insurance</li>
                                    <li>Motorcycle</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Franchise3rd