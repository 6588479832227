import React from 'react';
//Router
import { Link } from 'react-router-dom';
//Images
import Agnes from '../../img/team-agnes.jpg';
import Cathy from '../../img/Team-cath1.jpg';
import Marlon from '../../img/Team-marlon1.jpg';
import John from '../../img/Team-john1.jpg';
import Blank from '../../img/team-blank.jpg'
//styles CSS
import './TeamSection.css';

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const TeamSection = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="team-cont container">
            <div className="team-box-1">
                <h2 className="team-title-main">Our <span>Advisors.</span></h2>
                <p className="team-sub-main justify-p">We have professional consultants in our team. We are also always looking for new people to join our team. Our consultants know their stuff very well. Feel free to contact us for a quote.</p>
                <Link className="team-button" to="/team">View all team</Link>
            </div>
            <div className="team-box-2">
                <div className="team-1-cont">
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Agnes} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Agnes Deniega</h3>
                            <span className="team-post">General Manager</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Blank} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">-</h3>
                            <span className="team-post">Regional Head</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Marlon} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Marlon Thomas Marcellana</h3>
                            <span className="team-post">IT Department Head</span>
                        </div>
                    </div>
                </div>
                <div className="team-2-cont">
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={John} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">John Abejuro</h3>
                            <span className="team-post">Research and Development</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Cathy} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Catherine Car</h3>
                            <span className="team-post">Human Resource Head</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Blank} alt="avatar" />
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">-</h3>
                            <span className="team-post">CPA Finance Head</span>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default TeamSection;