import React from 'react';
//style CSS styles
import './CardGTL.css';
//Images
import GreatReturns from '../img/greatreturns.jpg';
import TimelessIndustry from '../img/timeless-industry.jpg';
import LinearOperations from '../img/linear-operations.jpg';

const CardGTL = () => {
    return (
        <div className="cardgtl-img-cont">
            <div className="cont-img-info">
                <img className="img-cardgtl" src={GreatReturns} alt="Great Returns" />
                <div>
                    <h3>Great Returns</h3>
                    <p className="justify-p">Compared to similarly priced FRANCHISE PACKAGES, LT&G boasts of a relatively faster ROI of 28 MONTHS!</p>
                </div>
            </div>
            <div className="cont-img-info">
                <img className="img-cardgtl" src={TimelessIndustry} alt="Timeless Industry" />
                <div>
                    <h3>Timeless Industry</h3>
                    <p className="justify-p">Financial Industry will always be relevant regardless of shifts in the market. You know what they say, "MONEY MAKES THE WORLD GO ROUND".</p>
                </div>
            </div>
            <div className="cont-img-info">
                <img className="img-cardgtl" src={LinearOperations} alt="Linear Operations" />
                <div>
                    <h3>Linear Operations</h3>
                    <p className="justify-p">The Business System is designed to produce maximum results with minimal effort. Say GOODBYE to concerns regarding SPOILAGE, LOW INVENTORY, or EXPENSIVE RENTALS.</p>
                </div>
            </div>
        </div>
    )
}

export default CardGTL;