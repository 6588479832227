import FranchiseAsiaSMX1 from '../img/franchiseasiasmx/smx-1-day-1.jpg';
import FranchiseAsiaSMX2 from '../img/franchiseasiasmx/smx-2-day-1.jpg';
import FranchiseAsiaSMX3 from '../img/franchiseasiasmx/smx-3-day-1.jpg';
import FranchiseAsiaSMX4 from '../img/franchiseasiasmx/smx-4-day-1.jpg';
import FranchiseAsiaSMX5 from '../img/franchiseasiasmx/smx-5-day-1.jpg';
import FranchiseAsiaSMX6 from '../img/franchiseasiasmx/smx-1-day-2.jpg';
import FranchiseAsiaSMX7 from '../img/franchiseasiasmx/smx-2-day-2.jpg';
import FranchiseAsiaSMX8 from '../img/franchiseasiasmx/smx-3-day-2.jpg';
import FranchiseAsiaSMX9 from '../img/franchiseasiasmx/smx-4-day-2.jpg';
import FranchiseAsiaSMX10 from '../img/franchiseasiasmx/smx-5-day-2.jpg';
import FranchiseAsiaSMX11 from '../img/franchiseasiasmx/smx-6-day-2.jpg';
import FranchiseAsiaSMX12 from '../img/franchiseasiasmx/smx-7-day-2.jpg';
import FranchiseAsiaSMX13 from '../img/franchiseasiasmx/smx-8-day-2.jpg';
import FranchiseAsiaSMX14 from '../img/franchiseasiasmx/smx-9-day-2.jpg';
import FranchiseAsiaSMX15 from '../img/franchiseasiasmx/smx-10-day-2.jpg';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: FranchiseAsiaSMX1
    },
    {
        id: 2,
        image: FranchiseAsiaSMX2
    },
    {
        id: 3,
        image: FranchiseAsiaSMX3
    },
    {
        id: 4,
        image: FranchiseAsiaSMX4
    },
    {
        id: 5,
        image: FranchiseAsiaSMX5
    },
    {
        id: 6,
        image: FranchiseAsiaSMX6
    },
    {
        id: 7,
        image: FranchiseAsiaSMX7
    },
    {
        id: 8,
        image: FranchiseAsiaSMX8
    },
    {
        id: 9,
        image: FranchiseAsiaSMX9
    },
    {
        id: 10,
        image: FranchiseAsiaSMX10
    },
    {
        id: 11,
        image: FranchiseAsiaSMX11
    },
    {
        id: 12,
        image: FranchiseAsiaSMX12
    },
    {
        id: 13,
        image: FranchiseAsiaSMX13
    },
    {
        id: 14,
        image: FranchiseAsiaSMX14
    },
    {
        id: 15,
        image: FranchiseAsiaSMX15
    }
];