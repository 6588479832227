import React from 'react';
//YoutubeEmbed
import YoutubeEmbed from "../../components/HomeComponents/YoutubeEmded";
//style CSS
import './ytembed.css';
import './AboutSection.css';
//Another Card Components
import CardGTL from '../CardGTL.js';

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const AboutSection = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="about-cont container">
            <div className="about-card-cont">
                <div className="about-t">
                    <h3>About</h3>
                    <h2>LT<span>&</span>G Credit Line</h2>
                </div>
                <div className="about-p-cont">
                    <div className="about-p">
                        <p className="pg pg-1 justify-p">The LT&G Credit Line provides loans for micro-entrepreneurs to fund their business growth. Borrowers enjoy the customized credit with friendlier terms, efficient risk-handling insurance, and other value-added services needed by small businesses. </p>
                        <p className="pg">Its franchise system is anchored on the mission of helping micro and small entrepreneurs succeed, while ensuring a profitable business for aspiring franchisees.   </p>
                    </div>
                    <div className="about-p mb-5">
                        <p className="pg pg-1 justify-p">Micro-enterprises such as sari-sari stores, small shops, eateries, local trading and the like, make up around 90% of all businesses in the Philippines. A huge number of them still find difficulty in accessing bank loans, who are the subjects of LT&G Credit Line.</p>
                        <p className="pg justify-p">LT&G envisions to be an industry leader in micro-lending, using its proven proprietary technical system and effective support mechanisms to its franchisee network nationwide.</p>
                    </div>
                </div>
                <div>
                    <YoutubeEmbed embedId="pBI8G4p1AOs" />
                </div>
            </div>
            <CardGTL />
        </motion.div>
    )
}

export default AboutSection;