import React from 'react'
//React Bootstrap Components
import Carousel from 'react-bootstrap/Carousel'
//style css
import './CarouselCont.css'
//Images
// import Image1 from '../../img/carousel/header-10.jpg'
// import Image2 from '../../img/carousel/header-11.jpg'
// import Image3 from '../../img/carousel/header-12.jpg'
// import Image4 from '../../img/carousel/header-15.jpg'
import Image6 from '../../img/carousel/header-1.jpg'
// import Image7 from '../../img/carousel/header-3.jpg'
import Image8 from '../../img/carousel/header-4.1.jpg'
// import Image9 from '../../img/carousel/header-18.jpg'
// import Image12 from '../../img/carousel/header-16.jpg'
// import Image13 from '../../img/carousel/header-17.jpg'
import Image14 from '../../img/carousel/header-19.jpg'
// import Image15 from '../../img/carousel/header-20.jpg'
import Image16 from '../../img/carousel/header-21.jpg'
import Image17 from '../../img/carousel/header-22.jpg'
import Image19 from '../../img/carousel/header-23.jpg'
import Image18 from '../../img/carousel/headerasia2.jpg'


//Link 
import { Link } from 'react-router-dom';


const CarouselCont = () => {
    return (
        <Carousel fade>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image19}
                    alt="1 slide"
                />
                </Carousel.Item>

                <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image18}
                    alt="1 slide"
                />
                </Carousel.Item>

            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image4}
                    alt="1 slide"
                />
                <Carousel.Caption>
                    <a className='register-btn' href="https://www.pfa.org.ph/event-details/davaoexpo"> Register </a>
                </Carousel.Caption>
            </Carousel.Item> */}
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image17}
                    alt="1 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image16}
                    alt="2 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image14}
                    alt="3 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image15}
                    alt="4 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item> */}
            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image12}
                    alt="5 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item> */}
            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image13}
                    alt="6 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item> */}
            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image9}
                    alt="7 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item> */}
            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image1}
                    alt="8 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item> */}
            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image2}
                    alt="9 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item> */}
            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image3}
                    alt="10 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item> */}
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image6}
                    alt="11 slide"
                />
                <Carousel.Caption>
                    <Link className="franchise-btn" to="/contact">Franchise Now</Link>
                </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image7}
                    alt="12 slide"
                />
                <Carousel.Caption>
                    <Link className="leave-message-btn" to="/contact">Leave a Message</Link>
                </Carousel.Caption>
            </Carousel.Item> */}
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image8}
                    alt="13 slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item>

            {/* <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Image7}
                    alt="Eighth slide"
                />
                <Carousel.Caption>
                    <Link className="leave-message-btn" to="/contact">Leave a Message</Link>
                </Carousel.Caption>
            </Carousel.Item> */}
        </Carousel>
    )
}

export default CarouselCont;