import React from "react";
//React Router-Dom
import { Link } from "react-router-dom";
//Style CSS
import './NewsAndEvents.css'
//Images
import Boss from "../../img/microlend-boss.jpg"
import Team from '../../img/teambuilding/team9.JPG'
import SanRafael from "../../img/san-rafael.jpg"
import SanMaria from "../../img/santa-maria-14.jpg"
import FranchiseExpoIndo from "../../img/franchise-expo.jpg"
import ExpoCebu from "../../img/cebu-expo.jpg"
import MeetPH from '../../img/meet-ph.jpg'
import FranchiseAsia from '../../img/franchise-asia-ph.jpg'
import AmazingRace from '../..//img/amazing-race.jpg'
import FranchiseAsia2022 from '../../img/franchiseasiasmx/smx-1-day-3.jpg'
import GMACavite from '../../img/newgmacavite/gma-cavite-1.jpg'
import Angeles from '../../img/newangeles/angeles-1.jpg'
import CebuSouth from '../../img/newcebusouth/cebusouth4.jpg'
import Midsayap from '../../img/newmidsayap/midsayap4.jpg'
//React-Icon
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'
//Component
import SocialMediaComponents from "./SocialMediaComponents";

import { motion } from 'framer-motion';
import { fadeAnimation } from '../animation';

const MicroLendComponents = () => {
    return (
        <div className="container nae-flex">
            <div className="nae-content-head">
                <motion.div
                    variants={fadeAnimation}
                    initial='hidden'
                    animate='show'
                    exit='exit'
                >
                    <div className="news-and-event-head">
                        <img src={Boss} alt="boss" />
                        <h4>MARCO ANTONIO M. SOLIMAN</h4>
                        <p>CEO and President</p>
                        <p>LT&G Credit Line Corp.</p>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Micro-lending firm gains ground by franchising</h2>
                        <p className="pub-margin">Published from <Link to="/microlend">manilastandard.net</Link></p>
                        <p><span>by Manila Standard Business</span></p>
                        <div>
                            <p>When LT&G Credit Line, a micro-lending company based in Bicol, saw that the company was becoming instrumental to more small-scale businesses for sustenance and growth, chief executive Marco Antonio Soliman immediately thought that they needed to expand across the nation.</p>
                            <p>With the help of Francorp, the worldwide leader in franchise development and consulting, LT&G Credit Line is currently making waves in the franchise industry because of its unique business concept—a credit line company offering just the right interest rate.</p>
                            <p>Established in 2013, the lending company has been the go-to of sari-sari stores, eateries (locally called carenderias) and similar small businesses when funding or capital falls short and getting a loan from commercial banks is a hassle. Six year later, it became so successful that many potential investors are applying for its franchise.</p>
                            <p>Profit from daily operations is what is keeping micro, small and medium enterprises or MSMEs, running. Soliman knows this not only because he finished a business management course in college. He ran a motorcycle spare parts shop in his hometown for two years which, he believes serves as his “master’s degree”.</p>
                        </div>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Working Capital</h2>
                        <div>
                            <p>Using the money he earned from working for a telecommunications company and from a loan from his mother, he opened the shop which only ran for two years. “I couldn’t compete with big players in our area, so I had to close the shop eventually,” Soliman says.</p>
                            <p>Soliman then went to study law and sought career opportunity in two insurance companies. It was there that he was able to save up more money and build business connections at the same time. He was introduced to the sport of practical shooting which encouraged him to put up a gun store which he named Legazpi Trends and Gears or LT&G.</p>
                            <p>“The insurance company to which I am still connected with, offered me a loan which I used to put up my own building,” Soliman says.</p>
                            <p>“I grabbed the opportunity and this is where I transferred the gun store along with a small café, a security agency and a trucking business [established with several business partners] and the first LT&G micro-lending firm,” he says.</p>
                            <p>Seeing that his micro-lending business is doing good, Soliman consulted friends who are into the banking industry about another business to operate. A friend who is connected with Francorp brought up the idea of franchising for his gun store or café.  Instead, he thought of applying the franchise suggestion to the micro-lending company which is a unique business concept that can also be franchised.</p>
                            <p>“At the back of my mind, I was thinking who would be interested to franchise when a lot of big banks and informal lenders have already saturated the market.  My friends are discouraging me to pursue my idea of having it franchised, but I believe, given the right opportunity for the people to understand the benefits from the business and the science about it, many existing and would-be business owners will be interested,” Soliman says.</p>
                            <p>Aside from the micro-lending firm earning the right amount of profit and its franchise model being able to provide jobs, Soliman says he is glad that the business is growing alongside its MSME clients. “We actually consider them partners, we want them to succeed in their business. Our interest rate is very friendly. We know that if we charge more, they will not earn from their business” he says.</p>
                            <p>Majority of LT&G micro-lending clients are sari-sari stores and eateries followed by similar small businesses like repair shops, vulcanizing shops, second-hand stores (ukay-ukay), water stations, among others. </p>
                            <p>“Our advantage is that we are offering a lower interest rate, and we are a legal business compared to informal lenders” Soliman says.  “With our business system, many of our borrowers are renewing their credit line several times over. Plus we see them grow their respective businesses which is the most rewarding part of our business.”</p>
                            <p>His case in point is a pili nut vendor in Legazpi City who sold his products in a heat-sealed plastic pouched and displayed under a makeshift stand by the roadside. After several loan renewals from LT&G, the seller now abides with DTI’s standards—the products are now neatly labeled and displayed inside a glass cabinet. Other businesses were mini-groceries now that were once sari-sari stores. </p>
                        </div>
                    </div>
                    <div className="news-and-event-content">
                        <h2>Evolving Franchise</h2>
                        <div>
                            <p>Soliman says the company is continuously evolving to be proactive on the risks involving such kind of business model. Each branch gets evaluated regularly to check on the risk-management measures. “We always try to find ways to minimize the risk vis a vis the acceptable budget,” he says.“</p>
                            <p>Lending can be the best or the worse business,” he says. “For us, we were able to study the science behind it. Qualifying the right borrowers is also a key factor.”</p>
                            <p>With LT&G’s partnership with Francorp, the company is expecting for more franchise outlets opening across the country.  From five branches, the company will be ending this year with 50 branches, of which 45 branches are all franchise-owned.</p>
                            <p>Francorp Philippines is a part of the worldwide network of Francorp International, a globally-leading franchise development and consulting firm that has helped develop over 3,000 franchise businesses and assisted more than 10,000 companies worldwide in their franchise expansion.</p>
                        </div>
                    </div>
                    <SocialMediaComponents />
                </motion.div>
                <div className="left-right-btn">
                    <div className="next-post-btn">
                        <Link to="/newlyopened-santamaria-branch"><HiOutlineArrowNarrowLeft /> Prev Post</Link>
                    </div>
                    <div className="next-post-btn">
                        <Link to="/expoindo">Next Post <HiOutlineArrowNarrowRight /></Link>
                    </div>
                </div>
            </div>

            <div className="other-post-cont">
                <h3>Other Posts</h3>
                <div className="other-post-subcont">
                    <div className="other-post-box ">
                        <img src={Midsayap} alt="opening-midsayap-northcotabato" />
                        <div>
                            <Link to="newlyopened-midsayap-branch">Newly Opened Midsayap, North Cotabato Branch</Link>
                            <p>March 28, 2023</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={CebuSouth} alt="opening-cebucity-cebu" />
                        <div>
                            <Link to="newlyopened-cebusouth-branch">Blessing of Cebu City, Cebu South Branch</Link>
                            <p>December 8, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box ">
                        <img src={Angeles} alt="opening-angeles-branch" />
                        <div>
                            <Link to="newlyopened-angeles-branch">Newly Opened Angeles City, Pampanga Branch</Link>
                            <p>November 12, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={GMACavite} alt="opening-gma-cavite" />
                        <div>
                            <Link to="newlyopened-gmacavite-branch">Newly Opened GMA, Cavite Branch</Link>
                            <p>November 9, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={FranchiseAsia2022} alt="franchise-expo-2022" />
                        <div>
                            <Link to="franchise-expo-manila">International Franchise Expo, Manila 2022</Link>
                            <p>October 14 - 16, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={Team} alt="team-building" />
                        <div>
                            <Link to="/team-building">Summer Outing and Team Building</Link>
                            <p>June 4 - 5, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box ">
                        <img src={SanRafael} alt="opening-branch" />
                        <div>
                            <Link to="/newlyopened-sanrafael-branch">Newly Opened San Rafael, Bulacan Branch</Link>
                            <p>March 4, 2022</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={SanMaria} alt="opening-branch" />
                        <div>
                            <Link to="/newlyopened-santamaria-branch">Newly Opened Santa Maria, Bulacan Branch</Link>
                            <p>December 4, 2021</p>
                        </div>
                    </div>
                    <div className="other-post-box select-active">
                        <img src={Boss} alt="micro-lending" />
                        <div>
                            <Link to="/microlend">Micro-lending firm gains ground by franchising</Link>
                            <p>July 8, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={FranchiseExpoIndo} alt="franchise expo indo" />
                        <div>
                            <Link to="/expoindo">Franchise Expo, Indonesia 2019</Link>
                            <p>July 8, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={ExpoCebu} alt="franchise expo cebu" />
                        <div>
                            <Link to="/expocebu">Franchise Expo, Cebu City 2019</Link>
                            <p>June 22, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={MeetPH} alt="meet ph" />
                        <div>
                            <Link to="/meetph">Meet PH’s first Micro Credit Financing on Franchise</Link>
                            <p>May 21, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={AmazingRace} alt="amazing race" />
                        <div>
                            <Link to="/amazingrace">AMAZING RACE 2019</Link>
                            <p>May 11, 2019</p>
                        </div>
                    </div>
                    <div className="other-post-box">
                        <img src={FranchiseAsia} alt="franchise asia" />
                        <div>
                            <Link to="/franchiseasia">Franchise Asia Philippines 2019</Link>
                            <p>April 14, 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MicroLendComponents;