import Angeles1 from '../img/newangeles/angeles-1.jpg';
import Angeles2 from '../img/newangeles/angeles-2.jpg';
import Angeles3 from '../img/newangeles/angeles-3.jpg';
import Angeles4 from '../img/newangeles/angeles-4.jpg';
import Angeles5 from '../img/newangeles/angeles-5.jpg';
import Angeles6 from '../img/newangeles/angeles-6.jpg';
import Angeles7 from '../img/newangeles/angeles-7.jpg';
import Angeles8 from '../img/newangeles/angeles-8.jpg';
import Angeles9 from '../img/newangeles/angeles-9.jpg';
import Angeles10 from '../img/newangeles/angeles-10.jpg';
import Angeles11 from '../img/newangeles/angeles-11.jpg';
import Angeles12 from '../img/newangeles/angeles-12.jpg';
import Angeles13 from '../img/newangeles/angeles-13.jpg';
import Angeles14 from '../img/newangeles/angeles-14.jpg';
import Angeles15 from '../img/newangeles/angeles-15.jpg';
import Angeles16 from '../img/newangeles/angeles-16.jpg';
import Angeles17 from '../img/newangeles/angeles-17.jpg';
import Angeles18 from '../img/newangeles/angeles-18.jpg';
import Angeles19 from '../img/newangeles/angeles-19.jpg';
import Angeles20 from '../img/newangeles/angeles-20.jpg';
import Angeles21 from '../img/newangeles/angeles-21.jpg';
import Angeles22 from '../img/newangeles/angeles-22.jpg';
import Angeles23 from '../img/newangeles/angeles-23.jpg';
import Angeles24 from '../img/newangeles/angeles-24.jpg';
import Angeles25 from '../img/newangeles/angeles-25.jpg';
import Angeles26 from '../img/newangeles/angeles-26.jpg';
import Angeles27 from '../img/newangeles/angeles-27.jpg';
import Angeles28 from '../img/newangeles/angeles-28.jpg';
import Angeles29 from '../img/newangeles/angeles-29.jpg';
import Angeles30 from '../img/newangeles/angeles-30.jpg';
import Angeles31 from '../img/newangeles/angeles-31.jpg';
import Angeles32 from '../img/newangeles/angeles-32.jpg';
import Angeles33 from '../img/newangeles/angeles-33.jpg';
import Angeles34 from '../img/newangeles/angeles-34.jpg';
import Angeles35 from '../img/newangeles/angeles-35.jpg';
import Angeles36 from '../img/newangeles/angeles-36.jpg';
import Angeles37 from '../img/newangeles/angeles-37.jpg';
import Angeles38 from '../img/newangeles/angeles-38.jpg';
import Angeles39 from '../img/newangeles/angeles-39.jpg';
import Angeles40 from '../img/newangeles/angeles-40.jpg';
import Angeles41 from '../img/newangeles/angeles-41.jpg';



// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        image: Angeles1
    },
    {
        id: 2,
        image: Angeles2
    },
    {
        id: 3,
        image: Angeles3
    },
    {
        id: 4,
        image: Angeles4
    },
    {
        id: 5,
        image: Angeles5
    },
    {
        id: 6,
        image: Angeles6
    },
    {
        id: 7,
        image: Angeles7
    },
    {
        id: 8,
        image: Angeles8
    },
    {
        id: 9,
        image: Angeles9
    },
    {
        id: 10,
        image: Angeles10
    },
    {
        id: 11,
        image: Angeles11
    },
    {
        id: 12,
        image: Angeles12
    },
    {
        id: 13,
        image: Angeles13
    },
    {
        id: 14,
        image: Angeles14
    },
    {
        id: 15,
        image: Angeles15
    },
    {
        id: 16,
        image: Angeles16
    },
    {
        id: 17,
        image: Angeles17
    },
    {
        id: 18,
        image: Angeles18
    },
    {
        id: 19,
        image: Angeles19
    },
    {
        id: 20,
        image: Angeles20
    },
    {
        id: 21,
        image: Angeles21
    },
    {
        id: 22,
        image: Angeles22
    },
    {
        id: 23,
        image: Angeles23
    },
    {
        id: 24,
        image: Angeles24
    },
    {
        id: 25,
        image: Angeles25
    },
    {
        id: 26,
        image: Angeles26
    },
    {
        id: 27,
        image: Angeles27
    },
    {
        id: 28,
        image: Angeles28
    },
    {
        id: 29,
        image: Angeles29
    },
    {
        id: 30,
        image: Angeles30
    },
    {
        id: 31,
        image: Angeles31
    },
    {
        id: 32,
        image: Angeles32
    },
    {
        id: 33,
        image: Angeles33
    },
    {
        id: 34,
        image: Angeles34
    },
    {
        id: 35,
        image: Angeles35
    },
    {
        id: 36,
        image: Angeles36
    },
    {
        id: 37,
        image: Angeles37
    },
    {
        id: 38,
        image: Angeles38
    },
    {
        id: 39,
        image: Angeles39
    },
    {
        id: 40,
        image: Angeles40
    },
    {
        id: 41,
        image: Angeles41
    },

];