import React from 'react';
//Router
import { Link } from 'react-router-dom';
//styles CSS
import './Card.css';
//Image
import Pres from '../../img/Sir-Marco-2.jpg';

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const Card = () => {
    const [element, controls] = useScroll();
    return (
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="card-cont container">
            <div className="card-cont-tpg">
                <div className="card-cont-pg">
                    <div className="card-t">
                        <h3>Operational Since 2015</h3>
                        <h2>LT&G Credit Line</h2>
                    </div>
                    <div className="card-pg">
                        <div>
                            <p className="card-p justify-p">Founded by its <span className="bold-p">President & CEO Mr. Marco Antonio M. Soliman</span>, LT&G Credit Line is a fast-growing leader in micro-lending and has been a home to many entrepreneurs in the Bicol region since 2015.</p>
                            <p className="card-p justify-p">It has a proven business model which can thrive in any populous location in the country as the numbers of micro entrepreneurs are exponentially growing.</p>
                        </div>
                        <div>
                            <p className="card-p justify-p">Since opening its first branch in Legazpi City, it has ventured into franchising thru MAMS LT&G Franchising Corp. which enabled it to expand to a total of <span className="bold-p">51 branches nationwide</span> in just one year.</p>
                            <p className="last-pg card-p justify-p">Partner with us in this excellent and pioneering micro lending business that supports small enterprises, create jobs, contributes to the growth of communities and make a difference in people’s lives.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-cont-img">
                <img className="img-pres" src={Pres} alt="CEO" />
                <div className="cont-pres-info">
                    <div className="cont-name-tp">
                        <h2>Marco Antonio M. Soliman</h2>
                        <h4>President & CEO</h4>
                    </div>
                    <Link className="read-more" to="/about" target="_blank">Read More</Link>
                </div>
            </div>
        </motion.div>
    )
}

export default Card;    