import React, { useState } from 'react';
//Google Api
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
//Data ltg-branches
import * as ltgData from '../../data/ltg-branches.json';
//Style CSS
import './MapSection.css';

const containerStyle = {
  width: '100%',
  height: '95vh',
  minHeight: '650px',
};

const center = {
  lat: 12.117220,
  lng: 122.734444
};

const options = {
  streetViewControl: true,
  rotateControl: true,
  scaleControl: true,
  zoomControl: true,
  mapTypeControl: false,
  fullscreenControl: true
}

const MapSection = () => {
  const [selectedBranch, setSelectedBranch] = useState(null);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCS56UEU6qB8RRk42ienFd_LE3j1vkvFrY"
    >
      <div>
        <div className="branches-title">
          <h2><span className="br-red">{ltgData.features.length}</span> Branches</h2>
          <h1>LT<span>&</span>G Credit Line Corp.</h1>
          <p>From year 2018 a fast growing branches across the Philippines started from 5 branches to {ltgData.features.length} branches as of now.</p>
        </div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={5.8}
          options={options}
        >
          { /* Child components, such as markers, info windows, etc. */}
          <>
            {ltgData.features.map(branch => (
              <Marker
                key={branch.properties.LTG_ID}
                position={{
                  lat: branch.geometry.coordinates[0],
                  lng: branch.geometry.coordinates[1]
                }}
                onClick={() => {
                  setSelectedBranch(branch);
                }}
                icon={{
                  url: '/ltg-marker.png',
                  scaledSize: { height: 40, width: 30 },
                }}
              />
            ))}
          </>
          {selectedBranch && (
            <InfoWindow
              className="info-window"
              position={{
                lat: selectedBranch.geometry.coordinates[0],
                lng: selectedBranch.geometry.coordinates[1]
              }}
              onCloseClick={() => {
                setSelectedBranch(null);
              }}
            >
              <div className="box-cont-marker">
                <h2>{selectedBranch.properties.NAME}</h2>
                <p className="box-address">{selectedBranch.properties.ADDRESS}</p>
                <div className="box-flex-marker">
                  {/* <div className="box-contact">
                    <h5>Contact no.:</h5>
                    {selectedBranch.properties.CONTACTS.map((contact) => 
                      <p>{contact}</p>
                    )}
                  </div>
                  <div className="box-email">
                    <h5>Email address:</h5>
                    {selectedBranch.properties.EMAILS.map((email) => 
                    <p>{email}</p>
                    )}
                  </div> */}
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </LoadScript>
  );
}

export default MapSection;